import { Controller } from "@hotwired/stimulus";
import Swiper, { Navigation, Thumbs } from "swiper";

Swiper.use([Navigation, Thumbs]);

export default class extends Controller {
  connect() {
    const thumbSwiper = new Swiper(".gallery-thumb-swiper-container", {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });

    new Swiper(".gallery-swiper-container", {
      spaceBetween: 10,
      navigation: {
        nextEl: ".gallery-swiper-navigation__next",
        prevEl: ".gallery-swiper-navigation__prev",
      },
      thumbs: {
        swiper: thumbSwiper,
        slideThumbActiveClass: "swiper-slide-thumb-active",
      },
    });
  }
}
