// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "trix";
import "@rails/actiontext";
import Swiper, { Navigation, Autoplay } from "swiper";

Swiper.use([Navigation]);

document.addEventListener("turbo:load", () => {
  new Swiper(".experiences-swiper-container", {
    autoplay: true,
    speed: 400,
    spaceBetween: 32,
    initialSlide: 2,
    centeredSlides: true,
    navigation: {
      nextEl: ".experiences-swiper-navigation-next",
      prevEl: ".experiences-swiper-navigation-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  new Swiper(".content-swiper-container", {
    modules: [Autoplay],
    autoplay: true,
    speed: 400,
    spaceBetween: 32,
    slidesPerView: 1,
    navigation: {
      nextEl: ".content-swiper-navigation__next",
      prevEl: ".content-swiper-navigation__prev",
    },
  });
});
