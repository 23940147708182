import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open() {
    document.body.classList.add("modal-open");
  }

  close(e) {
    e.preventDefault();
    this.element.remove();
    document.body.classList.remove("modal-open");
  }
}
