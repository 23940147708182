import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["placeholder", "button"];

  open(e) {
    e.preventDefault();

    this.element.classList.add("active");
  }
}
